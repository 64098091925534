<template>
  <div class="sso">
  </div>
</template>

<style lang="less" scope>
@Width: 192rem;
.sso {
  background-color: rgba(0,0,0,.4);
  width: 1920 / @Width;
  height: 1080 / @Width;
  padding: 50 / @Width;
  box-sizing: border-box;
}
</style>

<script>
export default {
  name: "Sso",
  data() {
    return {
      sysToken: this.$route.params.sysToken,
      id: this.$route.params.id,
    };
  },
  mounted() {
    this.saveSsoData()
  },
  methods: {
    saveSsoData: function () {
      // http://localhost:8080/#/sso/be9cf08f-a686-4b7f-8f9b-24f2dc708b99/14
      console.log('saveSsoData=>', this.sysToken)
      console.log('sso name=>', this.$route.name)
      if (!this.sysToken) {
        this.$router.push({name:'Login'})
      } else {
        localStorage.setItem('token', this.sysToken)
        this.$router.push({name: 'Project', params: {id: this.id}})
      }
    },
  },
};
</script>